/**
 * Correspondence  API 
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */


export interface AccountViewModel { 
    id?: number;
    code?: number;
    masterAccountEnumType?: AccountViewModel.MasterAccountEnumTypeEnum;
    accountTypeId?: number;
    accountTypeName?: string;
    accountName?: string;
    accountDescription?: string;
    currencyId?: number;
    currency?: string;
    parentId?: number;
    createdAt?: Date;
    lastModifiedTime?: Date;
    createdBy?: number;
    lastModifiedBy?: number;
    isDeleted?: boolean;
}
export namespace AccountViewModel {
    export type MasterAccountEnumTypeEnum = 'AccountPayable' | 'AccountReceivable' | 'Expenses' | 'Revenues' | 'Others';
    export const MasterAccountEnumTypeEnum = {
        AccountPayable: 'AccountPayable' as MasterAccountEnumTypeEnum,
        AccountReceivable: 'AccountReceivable' as MasterAccountEnumTypeEnum,
        Expenses: 'Expenses' as MasterAccountEnumTypeEnum,
        Revenues: 'Revenues' as MasterAccountEnumTypeEnum,
        Others: 'Others' as MasterAccountEnumTypeEnum
    };
}
