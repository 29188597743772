import { Component } from "@angular/core";
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: "smart-root",
    template: "<router-outlet></router-outlet>"
})
export class AppComponent {
    title = "מערכת לניהול מלאי";
    constructor(translate: TranslateService) {
        // this language will be used as a fallback when a translation isn't found in the current language
        translate.setDefaultLang(localStorage.getItem('lang'));

        // the lang to use, if the lang isn't available, it will use the current loader to get them
        translate.use(localStorage.getItem('lang'));
        this.title = translate.instant("storeManagement");
    }


}
