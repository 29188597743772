/**
 * Correspondence  API 
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { SalesItemViewModel } from './salesItemViewModel';


export interface SalesViewModel { 
    id?: number;
    purchaserId: number;
    transactionDate?: Date;
    salesNumber?: string;
    totalAmount?: number;
    commissionRatio?: number;
    invoiceStatus?: SalesViewModel.InvoiceStatusEnum;
    items?: Array<SalesItemViewModel>;
}
export namespace SalesViewModel {
    export type InvoiceStatusEnum = 'New' | 'Saved' | 'Paid' | 'PartialPaid' | 'Canceled';
    export const InvoiceStatusEnum = {
        New: 'New' as InvoiceStatusEnum,
        Saved: 'Saved' as InvoiceStatusEnum,
        Paid: 'Paid' as InvoiceStatusEnum,
        PartialPaid: 'PartialPaid' as InvoiceStatusEnum,
        Canceled: 'Canceled' as InvoiceStatusEnum
    };
}
