import { BrowserModule } from "@angular/platform-browser";
import { APP_INITIALIZER, NgModule } from "@angular/core";
import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { CoreModule } from "./core/core.module";
import { QuillModule } from "ngx-quill";
import { APIS, BASE_PATH, UserRoleService } from "./rest";
import { HTTP_INTERCEPTORS } from "@angular/common/http";
import { HttpInterceptorService } from "./http-interceptor.service";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { BsDatepickerModule } from "ngx-bootstrap/datepicker";
import {
  initUserSettingsSettings,
  UserSettingsService,
} from "./services/user-settings.service";
import { ServiceWorkerModule } from "@angular/service-worker";
import { environment } from "../environments/environment";
import { ModalModule } from "ngx-bootstrap/modal";
import { ToastrModule } from "ngx-toastr";
import { HttpClientModule, HttpClient } from '@angular/common/http';
import { TranslateModule, TranslateLoader, TranslateService, TranslateCompiler, TranslateParser } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { ApplicationInitializerFactory } from "./app.config";
import { Injector } from "@angular/core";
// AoT requires an exported function for factories

export function HttpLoaderFactory(http: HttpClient) {
    return new TranslateHttpLoader(http);
}


@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    AppRoutingModule,
    CoreModule,
    QuillModule.forRoot(),
    BrowserAnimationsModule, // required animations module
    ToastrModule.forRoot(),
    BsDatepickerModule.forRoot(),
    ModalModule.forRoot(),
    ServiceWorkerModule.register("ngsw-worker.js", {
      enabled: environment.production,
    }),
      TranslateModule.forRoot(
          {
              defaultLanguage: localStorage.getItem('lang'),
              loader: {
                  provide: TranslateLoader,
                  useFactory: HttpLoaderFactory,
                  deps: [HttpClient]
              },
              extend: true
          })
  ],
  providers: [
      {
          provide: BASE_PATH,
          useValue: "/backend"
      },
      {
          provide: APP_INITIALIZER,
          useFactory: ApplicationInitializerFactory,
          deps: [TranslateService, Injector],
          multi: true
      },
    APIS,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpInterceptorService,
      multi: true,
    },
    //{
    //  provide: APP_INITIALIZER,
    //  useFactory: initUserSettingsSettings,
    //  deps: [UserSettingsService],
    //  multi: true,
    //},
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
