import {NavigationItem} from "./store/navigation";

export const NavigationItems: NavigationItem[] = [
    {
        title: "פריטים שהתקבלו ",
        icon: "fal fa-info-circle",
        roles: ["1"],
        items: [
            {
                title: "receivedGoodsList",
                tags:  "receivedGoodsList",
                routerLink: "/goods",
                roles: ["15"]
            },
            {
                title: "receiveNewItem",
                tags: "receiveNewItem",
                routerLink: "/goods/new",
                roles: ["22"],
            },
            {
                title: " البضائع التالفة ",
                tags: " البضائع التالفة ",
                routerLink: "/damaged/",
                roles: ["26"],
            },
            {
                title: " اتلاف بضائع ",
                tags: " اتلاف بضائع ",
                routerLink: "/damaged/form",
                roles: ["25"],
            }
        ]
    },
    {
        title: "sale",
        icon: "fal fa-info-circle",
        roles: ["2"],
        items: [
            {
                title: "saleInvoiceList",
                tags:  "saleInvoiceList",
                routerLink: "/sales",
                roles: ["21"],

            },
            {
                title: "sellGoods",
                tags: "sellGoods",
                routerLink: "/sales/salesform",
                roles: ["22"],
            }
        ]
    },
    {
        title: "المحاسبة",
        icon: "fal fa-info-circle",
        roles: ["27"],
        items: [
            {
                title: "سندات الصرف  ",
                tags: "سندات الصرف ",
                routerLink: "/billing/paymentvouchers",
                roles: ["29"],

            },
            {
                title: "سندات القبض",
                tags: "سندات القبض ",
                routerLink: "/billing/receiptvouchers",
                roles: ["29"],
            }
        ]
    },
    {
        title: "reports",
        icon: "fal fa-chart-bar",
        roles: ["3"],

        items: [
            {
                title: "ملخص الحسابات",
                tags: "ملخص الحسابات ",
                routerLink: "/reports/accountSummary",
                roles: ["32"],

            },
            {
                title: " تقرير الأرباع",
                tags: " تقرير الأرباع",
                roles: ["30"],

                routerLink: "/reports/quartersreport"
            },
            {
                title: " تقرير تفصيل الحركات",
                tags: " تقرير تفصيل الحركات",
                routerLink: "/reports/accountdetails",
                roles: ["31"],

            },
            {
                title: "receivedItemReport",
                tags: "receivedItemReport",
                routerLink: "/reports/receivedItemsReport",
                roles: ["15"],

            },
            
            {
                title: "saleItemReport",
                tags: "saleItemReport",
                routerLink: "/reports/saleItemsReport",
                roles: ["15"],

            },
            {
                title: "itemReport",
                tags: "itemReport",
                routerLink: "/reports/itemReport",
                roles: ["15"],

            }
        ]
    },
    {
        title: "manageSystem",
        icon: "fal fa-cog",
         roles: ["4"],
        items: [
            {
                title: "goods",
                icon: "fal fa-info-circle",
                routerLink: "/lookup",
                roles: ["9"],

            },
            {
                title: "drivers",
                icon: "fal fa-car",
                routerLink: "/driver",
                roles: ["5"],

            },
            {
                title: "manageSellers",
                icon: "fal fa-person",
                routerLink: "/vendor",
                roles: ["10"],

            },
            {
                title: "manageClients",
                icon: "fal fa-person",
                routerLink: "/buyer",
                roles: ["11"],

            },
            {
                roles: ["8"],
                 title: "المجموعات",
                icon: "fal fa-person",
                routerLink: "/groups"

             },
            {
                roles: ["7"],
                 title: "المستخدمين",
                 routerLink: "/user"
            }
            //{
            //    title: "systemSettings",
            //    icon: "fal fa-info-circle",
            //    routerLink: "/systemsetting",
            //    roles: ["33"],

            //}
        ]
    },
];
