import { Injectable } from "@angular/core";
import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpHeaders,
  HttpInterceptor,
  HttpRequest,
  HttpResponse,
} from "@angular/common/http";
import { Observable, throwError, of, BehaviorSubject  } from "rxjs";
import { AuthService } from "./services/auth.service";
import { catchError, tap, retry, switchMap, map, filter, take, finalize} from "rxjs/operators";
import { SharedResponse } from "./rest";
import { Router } from "@angular/router";
import { ToastrService } from "ngx-toastr";
import { UsersService } from "src/app/rest";

@Injectable()
export class HttpInterceptorService implements HttpInterceptor {
  constructor(
    private authService: AuthService,
    private router: Router,
      private toastr: ToastrService,
      private usersService: UsersService
  ) {}
    private refreshTokenInProgress = false;
    private refreshTokenSubject: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {

      let pattern = /^.*\.(js|css|ts|ttf|json|woff2|webmanifest)$/;
      if (!this.authService.refreshToken && !req.url.includes('authenticate') ) {
          this.authService.unAuth();
          this.toastr.error("UnAuthorized", "UnAuthorized");
          this.router.navigate(["/auth/login"]);
      }

      req = this.addAuthenticationToken(req);

      return next.handle(req).pipe(
      tap((ev: HttpEvent<any>) => {
        if (ev instanceof HttpResponse) {
          const sharedResponse = ev.body as SharedResponse<any>;
          if (sharedResponse.messages && sharedResponse.messages.length > 0) {
            sharedResponse.messages.forEach((m) => {
              if (m.trim().length > 0) {
                  this.toastr.success(m, "בוצע");
              }
            });
          }
        }
      }),
      catchError((response) => {
        if (response instanceof HttpErrorResponse) {
            if (response.status === 401 || response.status === 403) {
                if (response.status === 401) {

                    if (this.refreshTokenInProgress) {

                        return this.refreshTokenSubject.pipe(
                            filter(result => result !== null),
                            take(1),
                            switchMap(() => next.handle(this.addAuthenticationToken(req)))
                        );
                    } else {
                        this.refreshTokenInProgress = true;
                        return this.refreshAccessToken().pipe(
                            switchMap((success: any) => {
                                this.authService.token = success.result.token;
                                this.authService.refreshToken = success.result.refreshToken;
                                this.authService.clientNo = success.result.clientNo;
                                this.authService.clientName = success.result.clientName;
                                this.authService.clientContactName = success.result.contactName;

                                sessionStorage.setItem("role", JSON.stringify(success.result.userClaims));
                                this.refreshTokenSubject.next(success);
                                return next.handle(this.addAuthenticationToken(req));
                            }), catchError((e,caught ) => caught),
                            // When the call to refreshToken completes we reset the refreshTokenInProgress to false
                            // for the next time the token needs to be refreshed
                            finalize(() => this.refreshTokenInProgress = false)
                        );


                    }
                }

            } else {
                if (req.url.includes("refreshToken")) {
                    this.authService.unAuth();
                    this.toastr.error("UnAuthorized", "UnAuthorized");
                    this.router.navigate(["/auth/login"]);
                }
              const sharedResponse = response.error as SharedResponse<any>;
              if (sharedResponse.messages && sharedResponse.messages.length > 0) {
                  sharedResponse.messages.forEach((m) => {
                      if (m.trim().length > 0) {
                          this.toastr.error(m, "שגיאה");
                      }
                  });
              }
          }
    
        }
        return throwError(response);
      })
    );
    }

  private addAuthenticationToken(request: HttpRequest<any>): HttpRequest<any> {


      if (request.url.includes('authenticate')) {
          return request.clone({
              headers: request.headers.
                  set("Authorization", "Bearer " + this.authService.token)

          });
      } else {
          if (request.url.includes('refreshToken')) {
              return request.clone({
                  headers: request.headers.
                      set("Authorization", "")
                      .set("refreshToken", this.authService.refreshToken)

              });
          } else {
              return request.clone({
                  headers: request.headers.
                      set("Authorization", "Bearer " + this.authService.token)
                      .set("refreshToken", this.authService.refreshToken)

              });
          }
        
      }
    
    }


    private refreshAccessToken(): Observable<any> {
      return   this.usersService.refreshToken();
    }


}
