import {Component, OnInit} from "@angular/core";
import {Store} from "@ngrx/store";
import { TranslateService } from "@ngx-translate/core";
import {AuthService, TokenUserModel} from "../../../services/auth.service";

@Component({
    selector: "smart-dropdown-user",
    templateUrl: "./dropdown-user.component.html",
})
export class DropdownUserComponent implements OnInit {
    user: TokenUserModel;

    constructor(private store: Store<any>, private authService: AuthService, translate: TranslateService) {
    }

    ngOnInit(): void {
        
        this.user = this.authService.decodedToken;
    }

}
