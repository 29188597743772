/**
 * Correspondence  API 
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent }                           from '@angular/common/http';
import { CustomHttpUrlEncodingCodec }                        from '../encoder';

import { Observable }                                        from 'rxjs';













import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';
import {SharedResponse,PaginatedListViewModel,DriverViewModel,BuyVegetablesViewModel,GoodsReceiptFilterViewModel,GoodsReceiptViewModel,ReceiptGoodsViewModel,GoodsReceiptDetailsViewModel,GoodsReceiptMainListViewModel,GoodReceiptMasterDetailsViewModel} from '../index';
import { AvailableQuantityViewModel } from "../model/goodsItemViewModel";
import { MasterGoodsDetailsVM } from "../model/goodsReceiptDetailsViewModel";


@Injectable()
export class GoodsReceiptService {

    protected basePath = 'https://localhost';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
        if (basePath) {
            this.basePath = basePath;
        }
        if (configuration) {
            this.configuration = configuration;
            this.basePath = basePath || configuration.basePath || this.basePath;
        }
    }

    /**
     * @param consumes string[] mime-types
     * @return true: consumes contains 'multipart/form-data', false: otherwise
     */
    private canConsumeForm(consumes: string[]): boolean {
        const form = 'multipart/form-data';
        for (const consume of consumes) {
            if (form === consume) {
                return true;
            }
        }
        return false;
    }


    /**
     * 
     * 
     * @param obj 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public buyItems(obj?: BuyVegetablesViewModel, observe?: 'body', reportProgress?: boolean): Observable<SharedResponse<GoodsReceiptDetailsViewModel>>;
    public buyItems(obj?: BuyVegetablesViewModel, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<SharedResponse<GoodsReceiptDetailsViewModel>>>;
    public buyItems(obj?: BuyVegetablesViewModel, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<SharedResponse<GoodsReceiptDetailsViewModel>>>;
    public buyItems(obj?: BuyVegetablesViewModel, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<SharedResponse<GoodsReceiptDetailsViewModel>>(`${this.basePath}/api/GoodsReceipt/BuyItems`,
            obj,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param obj 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public createGoodsReceipt(obj?: any, observe?: 'body', reportProgress?: boolean): Observable<SharedResponse<GoodsReceiptDetailsViewModel>>;
    public createGoodsReceipt(obj?: any, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<SharedResponse<GoodsReceiptDetailsViewModel>>>;
    public createGoodsReceipt(obj?: any, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<SharedResponse<GoodsReceiptDetailsViewModel>>>;
    public createGoodsReceipt(obj?: any, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<SharedResponse<GoodsReceiptDetailsViewModel>>(`${this.basePath}/api/GoodsReceipt/CreateGoodsReceipt`,
            obj,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param obj 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public postGoods(id?: any, observe?: 'body', reportProgress?: boolean): Observable<SharedResponse<boolean>>;
    public postGoods(id?: any, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<SharedResponse<boolean>>>;
    public postGoods(id?: any, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<SharedResponse<boolean>>>;
    public postGoods(id?: any, observe: any = 'body', reportProgress: boolean = false): Observable<any> {


        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.put<SharedResponse<boolean>>(`${this.basePath}/api/GoodsReceipt/PostGoods/${id}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param id 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public deleteGoodsReceipt(id?: number, observe?: 'body', reportProgress?: boolean): Observable<SharedResponse<boolean>>;
    public deleteGoodsReceipt(id?: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<SharedResponse<boolean>>>;
    public deleteGoodsReceipt(id?: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<SharedResponse<boolean>>>;
    public deleteGoodsReceipt(id?: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (id !== undefined && id !== null) {
            queryParameters = queryParameters.set('id', <any>id);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.delete<SharedResponse<boolean>>(`${this.basePath}/api/GoodsReceipt/DeleteGoodsReceipt`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param id 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public deleteMasterGoodsReceipt(id?: number, observe?: 'body', reportProgress?: boolean): Observable<SharedResponse<boolean>>;
    public deleteMasterGoodsReceipt(id?: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<SharedResponse<boolean>>>;
    public deleteMasterGoodsReceipt(id?: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<SharedResponse<boolean>>>;
    public deleteMasterGoodsReceipt(id?: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (id !== undefined && id !== null) {
            queryParameters = queryParameters.set('id', <any>id);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.delete<SharedResponse<boolean>>(`${this.basePath}/api/GoodsReceipt/DeleteMasterGoodsReceipt`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param obj 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public editMasterGoodsReceipt(obj?: any, observe?: 'body', reportProgress?: boolean): Observable<SharedResponse<GoodsReceiptDetailsViewModel>>;
    public editMasterGoodsReceipt(obj?: any, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<SharedResponse<GoodsReceiptDetailsViewModel>>>;
    public editMasterGoodsReceipt(obj?: any, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<SharedResponse<GoodsReceiptDetailsViewModel>>>;
    public editMasterGoodsReceipt(obj?: any, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<SharedResponse<ReceiptGoodsViewModel>>(`${this.basePath}/api/GoodsReceipt/EditMasterGoodsReceipt`,
            obj,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public generateReceivingSerial(observe?: 'body', reportProgress?: boolean): Observable<SharedResponse<string>>;
    public generateReceivingSerial(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<SharedResponse<string>>>;
    public generateReceivingSerial(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<SharedResponse<string>>>;
    public generateReceivingSerial(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.delete<SharedResponse<string>>(`${this.basePath}/api/GoodsReceipt/GenerateReceivingSerial`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getAllGoodsReceipt(observe?: 'body', reportProgress?: boolean): Observable<SharedResponse<Array<GoodsReceiptMainListViewModel>>>;
    public getAllGoodsReceipt(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<SharedResponse<Array<GoodsReceiptMainListViewModel>>>>;
    public getAllGoodsReceipt(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<SharedResponse<Array<GoodsReceiptMainListViewModel>>>>;
    public getAllGoodsReceipt(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<SharedResponse<Array<GoodsReceiptMainListViewModel>>>(`${this.basePath}/api/GoodsReceipt/GetAllGoodsReceipt`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param id 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getGoodsReceipt(id?: number, observe?: 'body', reportProgress?: boolean): Observable<SharedResponse<GoodsReceiptDetailsViewModel>>;
    public getGoodsReceipt(id?: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<SharedResponse<GoodsReceiptDetailsViewModel>>>;
    public getGoodsReceipt(id?: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<SharedResponse<GoodsReceiptDetailsViewModel>>>;
    public getGoodsReceipt(id?: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (id !== undefined && id !== null) {
            queryParameters = queryParameters.set('id', <any>id);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<SharedResponse<GoodsReceiptDetailsViewModel>>(`${this.basePath}/api/GoodsReceipt/GetGoodsReceipt`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param id 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public goodsMasterDetails(id?: number, observe?: 'body', reportProgress?: boolean): Observable<SharedResponse<GoodReceiptMasterDetailsViewModel>>;
    public goodsMasterDetails(id?: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<SharedResponse<GoodReceiptMasterDetailsViewModel>>>;
    public goodsMasterDetails(id?: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<SharedResponse<GoodReceiptMasterDetailsViewModel>>>;
    public goodsMasterDetails(id?: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (id !== undefined && id !== null) {
            queryParameters = queryParameters.set('id', <any>id);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<SharedResponse<GoodReceiptMasterDetailsViewModel>>(`${this.basePath}/api/GoodsReceipt/GoodsMasterDetails`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }


    /**
 * 
 * 
 * @param id 
 * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
 * @param reportProgress flag to report request and response progress.
 */
    public goodsMasterDetailsForEdit(id?: number, observe?: 'body', reportProgress?: boolean): Observable<SharedResponse<MasterGoodsDetailsVM>>;
    public goodsMasterDetailsForEdit(id?: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<SharedResponse<MasterGoodsDetailsVM>>>;
    public goodsMasterDetailsForEdit(id?: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<SharedResponse<MasterGoodsDetailsVM>>>;
    public goodsMasterDetailsForEdit(id?: number, observe: any = 'body', reportProgress: boolean = false): Observable<any> {


        let queryParameters = new HttpParams({ encoder: new CustomHttpUrlEncodingCodec() });
        if (id !== undefined && id !== null) {
            queryParameters = queryParameters.set('id', <any>id);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<SharedResponse<MasterGoodsDetailsVM>>(`${this.basePath}/api/GoodsReceipt/GoodsMasterDetailsForEdit`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
  * 
  * 
  * @param id 
  * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
  * @param reportProgress flag to report request and response progress.
  */
    public getAvailableQuantity(driverId: number, sellerId: number, itemId: number, observe?: 'body', reportProgress?: boolean): Observable<SharedResponse<Array<AvailableQuantityViewModel>>>;
    public getAvailableQuantity(driverId: number, sellerId: number, itemId: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<SharedResponse<Array<AvailableQuantityViewModel>>>>;
    public getAvailableQuantity(driverId: number, sellerId: number, itemId: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<SharedResponse<Array<AvailableQuantityViewModel>>>>;
    public getAvailableQuantity(driverId: number, sellerId: number, itemId: number, observe: any = 'body', reportProgress: boolean = false): Observable<any> {


        let queryParameters = new HttpParams({ encoder: new CustomHttpUrlEncodingCodec() });
        queryParameters = queryParameters.set('driverId', <any>driverId).set('itemId', <any>itemId).set('sellerId', <any>sellerId);

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<SharedResponse<Array<AvailableQuantityViewModel>>>(`${this.basePath}/api/GoodsReceipt/GetAvailableQuantity`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Search list
     * 
     * @param goodsReceiptFilterViewModel 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public search(goodsReceiptFilterViewModel?: GoodsReceiptFilterViewModel, observe?: 'body', reportProgress?: boolean): Observable<SharedResponse<Array<GoodsReceiptMainListViewModel>>>;
    public search(goodsReceiptFilterViewModel?: GoodsReceiptFilterViewModel, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<SharedResponse<Array<GoodsReceiptMainListViewModel>>>>;
    public search(goodsReceiptFilterViewModel?: GoodsReceiptFilterViewModel, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<SharedResponse<Array<GoodsReceiptMainListViewModel>>>>;
    public search(goodsReceiptFilterViewModel?: GoodsReceiptFilterViewModel, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json' 
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<SharedResponse<Array<GoodsReceiptMainListViewModel>>>(`${this.basePath}/api/GoodsReceipt/GetGoodsReceiptList`,
            goodsReceiptFilterViewModel,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param obj 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public updateGoodsReceipt(obj?: GoodsReceiptViewModel, observe?: 'body', reportProgress?: boolean): Observable<SharedResponse<GoodsReceiptViewModel>>;
    public updateGoodsReceipt(obj?: GoodsReceiptViewModel, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<SharedResponse<GoodsReceiptViewModel>>>;
    public updateGoodsReceipt(obj?: GoodsReceiptViewModel, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<SharedResponse<GoodsReceiptViewModel>>>;
    public updateGoodsReceipt(obj?: GoodsReceiptViewModel, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.put<SharedResponse<GoodsReceiptViewModel>>(`${this.basePath}/api/GoodsReceipt/UpdateGoodsReceipt`,
            obj,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

}
