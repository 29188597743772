import { NgModule, ModuleWithProviders, SkipSelf, Optional } from '@angular/core';
import { Configuration } from './configuration';
import { HttpClient } from '@angular/common/http';


import { AccountService } from './api/account.service';
import { DriverService } from './api/driver.service';
import { GoodsReceiptService } from './api/goodsReceipt.service';
import { LookupsService } from './api/lookups.service';
import { PdfGeneratorService } from './api/pdfGenerator.service';
import { PurchaserService } from './api/purchaser.service';
import { RoleService } from './api/role.service';
import { SalesService } from './api/sales.service';
import { SellerService } from './api/seller.service';
import { UploadFileService } from './api/uploadFile.service';
import { UserRoleService } from './api/userRole.service';
import { UsersService } from './api/users.service';
import { SharedService } from "./api/shared.service";
import { DamagedService } from "./api/damaged.service";
import { BillingService } from "./api/billing.service";

@NgModule({
  imports:      [],
  declarations: [],
  exports:      [],
  providers: [
    AccountService,
    DriverService,
    GoodsReceiptService,
    LookupsService,
    PdfGeneratorService,
    PurchaserService,
    RoleService,
    SalesService,
    SellerService,
    UploadFileService,
    UserRoleService,
      UsersService,
      SharedService, DamagedService, BillingService]
})
export class ApiModule {
    public static forRoot(configurationFactory: () => Configuration): ModuleWithProviders<ApiModule> {
        return {
            ngModule: ApiModule,
            providers: [ { provide: Configuration, useFactory: configurationFactory } ]
        };
    }

    constructor( @Optional() @SkipSelf() parentModule: ApiModule,
                 @Optional() http: HttpClient) {
        if (parentModule) {
            throw new Error('ApiModule is already loaded. Import in your base AppModule only.');
        }
        if (!http) {
            throw new Error('You need to import the HttpClientModule in your AppModule! \n' +
            'See also https://github.com/angular/angular/issues/20575');
        }
    }
}
