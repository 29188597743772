import {NgModule, Injector, APP_INITIALIZER } from "@angular/core";
import {CommonModule} from "@angular/common";
import {TooltipModule} from "ngx-bootstrap/tooltip";
import {NavFilterComponent} from "./nav-filter/nav-filter.component";
import {NavInfoCardComponent} from "./nav-info-card/nav-info-card.component";
import {NavComponent} from "./nav/nav.component";
import {NavFilterMsgComponent} from "./nav-filter-msg/nav-filter-msg.component";
import {RouterModule} from "@angular/router";
import {NavigationComponent} from "./navigation/navigation.component";
import {NavItemComponent} from "./nav-item/nav-item.component";
import {NavFooterComponent} from "./nav-footer/nav-footer.component";
import {UtilsModule} from "../utils/utils.module";
import { DirectivesModule } from "../directives/directives.module";
import { HttpClientModule, HttpClient } from '@angular/common/http';
import { TranslateModule, TranslateLoader, TranslateService } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { ApplicationInitializerFactory } from "../../app.config";


export function HttpLoaderFactory(http: HttpClient) {
    return new TranslateHttpLoader(http);
}
@NgModule({
    declarations: [
        NavFilterComponent, NavInfoCardComponent, NavComponent, NavFilterMsgComponent,
        NavigationComponent, NavItemComponent, NavFooterComponent
    ],
    imports: [
        CommonModule,
        RouterModule,
        TooltipModule,
        UtilsModule,
        DirectivesModule,
        TranslateModule.forRoot(
            {
                defaultLanguage: localStorage.getItem('lang'),
                loader: {
                    provide: TranslateLoader,
                    useFactory: HttpLoaderFactory,
                    deps: [HttpClient]
                },
                extend: true
            })
    ],
    exports: [
        NavFilterComponent, NavInfoCardComponent, NavComponent, NavFilterMsgComponent, NavigationComponent
    ],
    providers: [
        {
            provide: APP_INITIALIZER,
            useFactory: ApplicationInitializerFactory,
            deps: [TranslateService, Injector],
            multi: true
        },]
})
export class NavigationModule {
}
