/**
 * Correspondence  API 
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */


export interface GoodsItemViewModel { 
    itemId?: number;
    unitId?: number;
    quantity?: number;
    weight?: number;
    weightUnitId?: number;
    plasticCount?: number;
    sellerId?: number;
    masterReceiveId?: number;
    initialPrice?: number;
}

export class AvailableQuantityViewModel {
    receivingGoodsId?: number;
    description?: string;
    date?: Date;
    availableQuantity?: number;
 
}
