export const APP_CONFIG = {
    app: "מערכת לניהול מלאי",
    appName: "מערכת לניהול מלאי",
  user: "Adham Mehdawi",
  email: "mehdawiadham@gmail.com",
  twitter: "",
  avatar: "avatar-admin.png",
  version: "4.0.0",
  bs4v: "4.3",
  logo: "logo.png",
  logoM: "logo.png",
  // tslint:disable-next-line:max-line-length
  copyleft:
    '2020 ©  نظام ادارة المخزون  بواسطة &nbsp;<a href="#" class="text-primary fw-500" title="gotbootstrap.com" target="_blank">ٌ </a>'
  // tslint:disable-next-line:max-line-length
  // copyleftInverse: '2019 © Newsoft by&nbsp;<a href="http://www.newsoft.ps" class="text-primary fw-500" title="gotbootstrap.com" target="_blank">Newsoft </a>',
};
import { Injector } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { LOCATION_INITIALIZED } from '@angular/common';
import { TranslateService } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';

export function ApplicationInitializerFactory(
    translate: TranslateService, injector: Injector) {
    return async () => {
        await injector.get(LOCATION_INITIALIZED, Promise.resolve(null));

        const deaultLang = 'he';
        localStorage.setItem('lang', deaultLang);
        translate.addLangs(['en', 'ar','he']);
        translate.setDefaultLang(deaultLang);
        try {
            await translate.use(deaultLang).toPromise();
        } catch (err) {
            console.log(err);
        }
        console.log(`Successfully initialized ${deaultLang} language.`);
    };
}