import {Directive, ElementRef, HostListener, Input} from "@angular/core";
import {first} from "rxjs/operators";
import {UploadFileService} from "../../rest";

@Directive({
  selector: "[SecureFileDownload]"
})
export class SecureFileDownloadDirective {

  @Input() fileKey: string;
  @Input() fileName: string;

  constructor(private el: ElementRef, private publicFileService: UploadFileService) {
  }

  @HostListener("click") onClick() {

    this.publicFileService.download(this.fileKey).pipe(first()).subscribe(res => {
      const anchor = document.createElement("a");
      anchor.href = URL.createObjectURL(res);
      anchor.download = this.fileName;
      anchor.dataset.downloadurl = [res.type, anchor.download, anchor.href].join(":");
      anchor.click();
    });

  }

}
