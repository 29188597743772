import {Directive, ElementRef, HostListener, Input} from "@angular/core";
import {UploadFileService} from "../../rest";
import {first} from "rxjs/operators";

@Directive({
    selector: "[secureFilePreview]"
})
export class SecureFilePreviewDirective {

    @Input() fileKey: string;
    @Input() fileName: string;

    constructor(private el: ElementRef, private publicFileService: UploadFileService) {
    }

    @HostListener("click") onClick() {

        this.publicFileService.download(this.fileKey).pipe(first()).subscribe(res => {
            const anchor = document.createElement("a");
            anchor.href = URL.createObjectURL(res);
            // anchor.download = this.fileName;
            anchor.target = "_blank";
            // anchor.dataset.downloadurl = [res.type, anchor.download, anchor.href].join(":");
            anchor.click();
        });

    }
}
