import {NgModule} from "@angular/core";
import {CommonModule} from "@angular/common";
import {ConfirmDialogComponent} from "./confirm-dialog/confirm-dialog.component";
import {DialogsService} from "./dialogs.service";
import {DragDropModule} from "@angular/cdk/drag-drop";
import {FormsModule} from "@angular/forms";
import {NgSelectModule} from "@ng-select/ng-select";
import { BsModalRef, ModalModule } from "ngx-bootstrap/modal";
import { BsDatepickerModule } from "ngx-bootstrap/datepicker";

@NgModule({
    declarations: [
        ConfirmDialogComponent
    ],
    entryComponents: [
        ConfirmDialogComponent
    ],
    imports: [
        CommonModule,
        ModalModule,
        DragDropModule,
        FormsModule,
        NgSelectModule,
        BsDatepickerModule.forRoot()
    ],
    providers: [DialogsService, BsModalRef]
})
export class DialogsModule {
}
