/**
 * Correspondence  API 
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */


export class GoodsReceiptViewModel { 
    id?: number;
    quantity?: number;
    initialPrice?: number;
    weight?: number;
    plasticCount?: number;
    driverId?: number;
    sellerId?: number;
    itemId?: number;
    unitId?: number;
    driverName: string;
    sellerName: string;
    doneEditing?: boolean;
}

export class GoodsReceiptFilter{
    driverId?: number;
    sellerId?: number;
    itemId?: number;
    fromDate?: string;
    toDate?: string;

}

export class ItemReportViewModel {
    id?: number;
    quantity?: number;
    itemName: string;
    itemCode: string;
    unitName: string;
}