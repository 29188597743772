import {Injectable} from "@angular/core";

@Injectable({
    providedIn: "root"
})
export class PrintService {

    constructor() {
    }

    print(printElement: HTMLElement, addHeader = true) {

        if (document.getElementById != null) {
            let html = "<HTML>\n<HEAD>\n";
            if (document.getElementsByTagName != null) {
                const headTags = document.getElementsByTagName("head");
                if (headTags.length > 0) {
                    html += headTags[0].innerHTML;
                }
            }

            html += "\n</HE" + "AD>\n<BODY>\n";
            console.log(addHeader);

            if (addHeader) {
                html += `<div dir="rtl" style="">
                <table border="0" class="table" style="text-align: center;">
                    <tbody style="">
                    <tr style="">
                        <td style="width: 33%;border-top: 0;text-align: center;">دولة فلسطبن</td>
                        <td style="width: 33%;border-top: 0;text-align: center;"><p style="    text-align: center;">
                            <img src="assets/img/logo.png"
                                 style="width: 77.4px; height: 119.998px;"><br></p></td>
                        <td style="width: 33%;border-top: 0;text-align: center;">
                            State Of Palestine
                        </td>
                    </tr>
                    </tbody>
                </table>
            </div>`;
            }

            const printReadyElem = printElement;

            if (printReadyElem != null) {
                html += printReadyElem.innerHTML;
            } else {
                alert("Error, no contents.");
                return;
            }

            html += "\n</BO" + "DY>\n</HT" + "ML>";
            const printWin = window.open("", "processPrint");
            printWin.document.open();
            printWin.document.write(html);
            printWin.document.onreadystatechange = () => {
                if (document.readyState === "complete") {
                    printWin.print();
                }
            };
            printWin.document.close();
        } else {
            alert("Browser not supported.");
        }

    }
}
