import {ChangeDetectionStrategy, Component, OnInit} from "@angular/core";
import {Store} from "@ngrx/store";
import {toggleFilter} from "src/app/store/navigation";
import {AuthService, TokenUserModel} from "../../../services/auth.service";

@Component({
    selector: "smart-nav-info-card",
    templateUrl: "./nav-info-card.component.html",
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class NavInfoCardComponent implements OnInit {
    user: TokenUserModel;

    constructor(private store: Store<any>, private authService: AuthService) {
    }

    toggleFilter($event: MouseEvent) {
        $event.preventDefault();
        this.store.dispatch(toggleFilter());

    }

    ngOnInit(): void {
        this.user = this.authService.decodedToken;
    }
}
