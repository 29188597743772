import {Directive, ElementRef, Input, OnInit} from "@angular/core";
import {distinctUntilChanged} from "rxjs/operators";
import {NewSoftImageServiceService} from "./new-soft-image-service.service";

@Directive({
    selector: "[secureImageDirective]"
})
export class SecureImageDirectiveDirective implements OnInit {
    @Input() userId: number | string;

    constructor(private el: ElementRef, private publicFileService: NewSoftImageServiceService) {
    }

    ngOnInit(): void {

        if (!this.userId) {
            this.el.nativeElement.setAttribute("src", "assets/img/demo/avatars/Avatar-Missing.png");
            return;
        }
        this.publicFileService.downloadFile(+this.userId, 1).pipe(distinctUntilChanged()).subscribe(res => {
            const reader = new FileReader();
            reader.readAsDataURL(res);
            reader.onerror = () => {
                this.el.nativeElement.setAttribute("src", "assets/img/demo/avatars/Avatar-Missing.png");
            };
            reader.onloadend = () => {
                this.el.nativeElement.setAttribute("src", reader.result);
            };
        }, error => {
            this.el.nativeElement.setAttribute("src", "assets/img/demo/avatars/Avatar-Missing.png");
        });
    }
}
