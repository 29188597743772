import {Injectable} from "@angular/core";
import {UserRoleService} from "../rest";

export function initUserSettingsSettings(userSettingsService: UserSettingsService): () => Promise<any> {
    return () => userSettingsService.initialize();
}

@Injectable({
    providedIn: "root"
})
export class UserSettingsService {

    constructor(private userRoleService: UserRoleService) {
    }

    get roles(): string[] {
        const rolesList = sessionStorage.getItem("role");
        if (rolesList) {
            return JSON.parse(rolesList);
        } else {
            return [];
        }
    }

    initialize() {
        return new Promise((resolve, reject) => {
            this.userRoleService.getMyRoles().subscribe(res => {
                sessionStorage.setItem("role", JSON.stringify(res.result));
                // sessionStorage.setItem("role", JSON.stringify(["emp"]));
                resolve();
            });
        });
    }
}
