import { CommonModule } from "@angular/common";
import { DynamicTableComponent } from "./dynamic-table/dynamic-table.component";
import { FormsModule } from "@angular/forms";
import { NgModule } from "@angular/core";
import { RemoteGridComponent } from './remote-grid/remote-grid.component';
import { HttpClientModule, HttpClient } from '@angular/common/http';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';

export function HttpLoaderFactory(http: HttpClient) {
    return new TranslateHttpLoader(http);
}

@NgModule({
  declarations: [DynamicTableComponent, RemoteGridComponent],
    exports: [DynamicTableComponent, RemoteGridComponent],
    imports: [CommonModule, FormsModule,
        TranslateModule.forRoot(
            {
                defaultLanguage: localStorage.getItem('lang'),
                loader: {
                    provide: TranslateLoader,
                    useFactory: HttpLoaderFactory,
                    deps: [HttpClient]
                }
            })],
})
export class TablesModule {}
