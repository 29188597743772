import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {BuyerFormComponent} from "./buyer-form.component";
import {PanelsModule} from "../../../shared/panels/panels.module";
import {TablesModule} from "../../tables/tables.module";
import {NgSelectModule} from "@ng-select/ng-select";
import {FormsModule} from "@angular/forms";
import { HttpClientModule, HttpClient } from '@angular/common/http';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';

export function HttpLoaderFactory(http: HttpClient) {
    return new TranslateHttpLoader(http);
}

@NgModule({
    declarations: [BuyerFormComponent],
    entryComponents: [BuyerFormComponent],
    exports: [BuyerFormComponent],
    imports: [
        CommonModule,
        PanelsModule,
        TablesModule,
        NgSelectModule,
        FormsModule,
        TranslateModule.forRoot(
            {
                defaultLanguage: localStorage.getItem('lang'),
                loader: {
                    provide: TranslateLoader,
                    useFactory: HttpLoaderFactory,
                    deps: [HttpClient]
                }
            })
    ],
})
export class BuyerFormModule {
}
