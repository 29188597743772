/**
 * Correspondence  API 
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */


export interface MarginSettings { 
    unit?: MarginSettings.UnitEnum;
    top?: number;
    bottom?: number;
    left?: number;
    right?: number;
}
export namespace MarginSettings {
    export type UnitEnum = 'Inches' | 'Millimeters' | 'Centimeters';
    export const UnitEnum = {
        Inches: 'Inches' as UnitEnum,
        Millimeters: 'Millimeters' as UnitEnum,
        Centimeters: 'Centimeters' as UnitEnum
    };
}
