/**
 * Correspondence  API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1
 *
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional } from "@angular/core";
import {
  HttpClient,
  HttpHeaders,
  HttpParams,
  HttpResponse,
  HttpEvent,
} from "@angular/common/http";
import { CustomHttpUrlEncodingCodec } from "../encoder";

import { Observable } from "rxjs";

import { BASE_PATH, COLLECTION_FORMATS } from "../variables";
import { Configuration } from "../configuration";
import {
  SharedResponse,
  PaginatedListViewModel,
  SalesViewModel,
  SellerViewModel,
  SellerForDropdown,
} from "../index";
import { Invoice } from "src/app/features/sales/sales-invoice/invoice";
import { ok } from 'assert';
import { environment } from 'src/environments/environment';

@Injectable()
export class SellerService {
  protected basePath = "https://localhost";
  public defaultHeaders = new HttpHeaders();
  public configuration = new Configuration();

  constructor(
    protected httpClient: HttpClient,
    @Optional() @Inject(BASE_PATH) basePath: string,
    @Optional() configuration: Configuration
  ) {
    if (basePath) {
      this.basePath = basePath;
    }
    if (configuration) {
      this.configuration = configuration;
      this.basePath = basePath || configuration.basePath || this.basePath;
    }
  }

  /**
   * @param consumes string[] mime-types
   * @return true: consumes contains 'multipart/form-data', false: otherwise
   */
  private canConsumeForm(consumes: string[]): boolean {
    const form = "multipart/form-data";
    for (const consume of consumes) {
      if (form === consume) {
        return true;
      }
    }
    return false;
  }

  /**
   *
   *
   * @param obj
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public createSeller(
    obj?: SellerViewModel,
    observe?: "body",
    reportProgress?: boolean
  ): Observable<SharedResponse<SellerViewModel>>;
  public createSeller(
    obj?: SellerViewModel,
    observe?: "response",
    reportProgress?: boolean
  ): Observable<HttpResponse<SharedResponse<SellerViewModel>>>;
  public createSeller(
    obj?: SellerViewModel,
    observe?: "events",
    reportProgress?: boolean
  ): Observable<HttpEvent<SharedResponse<SellerViewModel>>>;
  public createSeller(
    obj?: SellerViewModel,
    observe: any = "body",
    reportProgress: boolean = false
  ): Observable<any> {
    let headers = this.defaultHeaders;

    // to determine the Accept header
    let httpHeaderAccepts: string[] = ["application/json"];
    const httpHeaderAcceptSelected:
      | string
      | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set("Accept", httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [
      "application/json-patch+json",
      "application/json",
      "text/json",
      "application/_*+json",
    ];
    const httpContentTypeSelected:
      | string
      | undefined = this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected != undefined) {
      headers = headers.set("Content-Type", httpContentTypeSelected);
    }
        
        
debugger
    return this.httpClient.post<SharedResponse<SellerViewModel>>(
      `${this.basePath}/api/Seller/CreateSeller`,
      obj,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      }
    );
  }

  /**
   *
   *
   * @param id
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public deleteSeller(
    id?: number,
    observe?: "body",
    reportProgress?: boolean
  ): Observable<SharedResponse<boolean>>;
  public deleteSeller(
    id?: number,
    observe?: "response",
    reportProgress?: boolean
  ): Observable<HttpResponse<SharedResponse<boolean>>>;
  public deleteSeller(
    id?: number,
    observe?: "events",
    reportProgress?: boolean
  ): Observable<HttpEvent<SharedResponse<boolean>>>;
  public deleteSeller(
    id?: number,
    observe: any = "body",
    reportProgress: boolean = false
  ): Observable<any> {
    let queryParameters = new HttpParams({
      encoder: new CustomHttpUrlEncodingCodec(),
    });
    if (id !== undefined && id !== null) {
      queryParameters = queryParameters.set("id", <any>id);
    }

    let headers = this.defaultHeaders;

    // to determine the Accept header
    let httpHeaderAccepts: string[] = ["application/json"];
    const httpHeaderAcceptSelected:
      | string
      | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set("Accept", httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient.delete<SharedResponse<boolean>>(
      `${this.basePath}/api/Seller/DeleteSeller`,
      {
        params: queryParameters,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      }
    );
  }

  /**
   *
   *
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public getAllSeller(
    observe?: "body",
    reportProgress?: boolean
  ): Observable<SharedResponse<Array<SellerViewModel>>>;
  public getAllSeller(
    observe?: "response",
    reportProgress?: boolean
  ): Observable<HttpResponse<SharedResponse<Array<SellerViewModel>>>>;
  public getAllSeller(
    observe?: "events",
    reportProgress?: boolean
  ): Observable<HttpEvent<SharedResponse<Array<SellerViewModel>>>>;
  public getAllSeller(
    observe: any = "body",
    reportProgress: boolean = false
  ): Observable<any> {
    let headers = this.defaultHeaders;

    // to determine the Accept header
    let httpHeaderAccepts: string[] = ["application/json"];
    const httpHeaderAcceptSelected:
      | string
      | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set("Accept", httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient.get<SharedResponse<Array<SellerViewModel>>>(
      `${this.basePath}/api/Seller/GetAllSeller`,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      }
    );
  }

  /**
   *
   *
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public getForDropdown(
    observe?: "body",
    reportProgress?: boolean
  ): Observable<SharedResponse<Array<SellerForDropdown>>>;
  public getForDropdown(
    observe?: "response",
    reportProgress?: boolean
  ): Observable<HttpResponse<SharedResponse<Array<SellerForDropdown>>>>;
  public getForDropdown(
    observe?: "events",
    reportProgress?: boolean
  ): Observable<HttpEvent<SharedResponse<Array<SellerForDropdown>>>>;
  public getForDropdown(
    observe: any = "body",
    reportProgress: boolean = false
  ): Observable<any> {
    let headers = this.defaultHeaders;

    // to determine the Accept header
    let httpHeaderAccepts: string[] = ["application/json"];
    const httpHeaderAcceptSelected:
      | string
      | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set("Accept", httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient.get<SharedResponse<Array<SellerForDropdown>>>(
      `${this.basePath}/api/Seller/GetForDropdown`,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      }
    );
  }

  /**
   *
   *
   * @param id
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public getSeller(
    id?: number,
    observe?: "body",
    reportProgress?: boolean
  ): Observable<SharedResponse<SellerViewModel>>;
  public getSeller(
    id?: number,
    observe?: "response",
    reportProgress?: boolean
  ): Observable<HttpResponse<SharedResponse<SellerViewModel>>>;
  public getSeller(
    id?: number,
    observe?: "events",
    reportProgress?: boolean
  ): Observable<HttpEvent<SharedResponse<SellerViewModel>>>;
  public getSeller(
    id?: number,
    observe: any = "body",
    reportProgress: boolean = false
  ): Observable<any> {
    let queryParameters = new HttpParams({
      encoder: new CustomHttpUrlEncodingCodec(),
    });
    if (id !== undefined && id !== null) {
      queryParameters = queryParameters.set("id", <any>id);
    }

    let headers = this.defaultHeaders;

    // to determine the Accept header
    let httpHeaderAccepts: string[] = ["application/json"];
    const httpHeaderAcceptSelected:
      | string
      | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set("Accept", httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient.get<SharedResponse<SellerViewModel>>(
      `${this.basePath}/api/Seller/GetSeller`,
      {
        params: queryParameters,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      }
    );
  }

  /**
   *
   *
   * @param obj
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public updateSeller(
    obj?: SellerViewModel,
    observe?: "body",
    reportProgress?: boolean
  ): Observable<SharedResponse<SellerViewModel>>;
  public updateSeller(
    obj?: SellerViewModel,
    observe?: "response",
    reportProgress?: boolean
  ): Observable<HttpResponse<SharedResponse<SellerViewModel>>>;
  public updateSeller(
    obj?: SellerViewModel,
    observe?: "events",
    reportProgress?: boolean
  ): Observable<HttpEvent<SharedResponse<SellerViewModel>>>;
  public updateSeller(
    obj?: SellerViewModel,
    observe: any = "body",
    reportProgress: boolean = false
  ): Observable<any> {
    let headers = this.defaultHeaders;

    // to determine the Accept header
    let httpHeaderAccepts: string[] = ["application/json"];
    const httpHeaderAcceptSelected:
      | string
      | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set("Accept", httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [
      "application/json-patch+json",
      "application/json",
      "text/json",
      "application/_*+json",
    ];
    const httpContentTypeSelected:
      | string
      | undefined = this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected != undefined) {
      headers = headers.set("Content-Type", httpContentTypeSelected);
    }

    return this.httpClient.put<SharedResponse<SellerViewModel>>(
      `${this.basePath}/api/Seller/UpdateSeller`,
      obj,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      }
    );
  }

  //////////////

  



   
  //  getAllInvoiceSeller(): Observable<any> {
  // return this.httpClient.post<any>(`${this.basePath}/api/Sales/GetSalesInvoiceList`, environment.httpOptions);
  //}


  
}
