/**
 * Correspondence  API 
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent }                           from '@angular/common/http';
import { CustomHttpUrlEncodingCodec }                        from '../encoder';

import { Observable }                                        from 'rxjs';







import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';
import {SharedResponse,PaginatedListViewModel,PurchaserViewModel,PurchaserForDropdown} from '../index';


@Injectable()
export class PurchaserService {

    protected basePath = 'https://localhost';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
        if (basePath) {
            this.basePath = basePath;
        }
        if (configuration) {
            this.configuration = configuration;
            this.basePath = basePath || configuration.basePath || this.basePath;
        }
    }

    /**
     * @param consumes string[] mime-types
     * @return true: consumes contains 'multipart/form-data', false: otherwise
     */
    private canConsumeForm(consumes: string[]): boolean {
        const form = 'multipart/form-data';
        for (const consume of consumes) {
            if (form === consume) {
                return true;
            }
        }
        return false;
    }


    /**
     * 
     * 
     * @param obj 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public createPurchaser(obj?: PurchaserViewModel, observe?: 'body', reportProgress?: boolean): Observable<SharedResponse<PurchaserViewModel>>;
    public createPurchaser(obj?: PurchaserViewModel, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<SharedResponse<PurchaserViewModel>>>;
    public createPurchaser(obj?: PurchaserViewModel, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<SharedResponse<PurchaserViewModel>>>;
    public createPurchaser(obj?: PurchaserViewModel, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<SharedResponse<PurchaserViewModel>>(`${this.basePath}/api/Purchaser/CreatePurchaser`,
            obj,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param id 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public deletePurchaser(id?: number, observe?: 'body', reportProgress?: boolean): Observable<SharedResponse<boolean>>;
    public deletePurchaser(id?: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<SharedResponse<boolean>>>;
    public deletePurchaser(id?: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<SharedResponse<boolean>>>;
    public deletePurchaser(id?: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (id !== undefined && id !== null) {
            queryParameters = queryParameters.set('id', <any>id);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.delete<SharedResponse<boolean>>(`${this.basePath}/api/Purchaser/DeletePurchaser`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getAllPurchaser(observe?: 'body', reportProgress?: boolean): Observable<SharedResponse<Array<PurchaserViewModel>>>;
    public getAllPurchaser(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<SharedResponse<Array<PurchaserViewModel>>>>;
    public getAllPurchaser(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<SharedResponse<Array<PurchaserViewModel>>>>;
    public getAllPurchaser(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<SharedResponse<Array<PurchaserViewModel>>>(`${this.basePath}/api/Purchaser/GetAllPurchaser`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getForDropdown(observe?: 'body', reportProgress?: boolean): Observable<SharedResponse<Array<PurchaserForDropdown>>>;
    public getForDropdown(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<SharedResponse<Array<PurchaserForDropdown>>>>;
    public getForDropdown(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<SharedResponse<Array<PurchaserForDropdown>>>>;
    public getForDropdown(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<SharedResponse<Array<PurchaserForDropdown>>>(`${this.basePath}/api/Purchaser/GetForDropdown`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param id 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getPurchaser(id?: number, observe?: 'body', reportProgress?: boolean): Observable<SharedResponse<PurchaserViewModel>>;
    public getPurchaser(id?: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<SharedResponse<PurchaserViewModel>>>;
    public getPurchaser(id?: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<SharedResponse<PurchaserViewModel>>>;
    public getPurchaser(id?: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (id !== undefined && id !== null) {
            queryParameters = queryParameters.set('id', <any>id);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<SharedResponse<PurchaserViewModel>>(`${this.basePath}/api/Purchaser/GetPurchaser`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param obj 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public updatePurchaser(obj?: PurchaserViewModel, observe?: 'body', reportProgress?: boolean): Observable<SharedResponse<PurchaserViewModel>>;
    public updatePurchaser(obj?: PurchaserViewModel, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<SharedResponse<PurchaserViewModel>>>;
    public updatePurchaser(obj?: PurchaserViewModel, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<SharedResponse<PurchaserViewModel>>>;
    public updatePurchaser(obj?: PurchaserViewModel, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.put<SharedResponse<PurchaserViewModel>>(`${this.basePath}/api/Purchaser/UpdatePurchaser`,
            obj,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

}
