import { Injectable } from "@angular/core";
import { JwtHelperService } from "@auth0/angular-jwt";

@Injectable({
  providedIn: "root"
})
export class AuthService {
  helper = new JwtHelperService();

  constructor() {}

  get expirationDate() {
    return this.helper.getTokenExpirationDate(this.token);
  }

  get decodedToken(): TokenUserModel {
    return this.helper.decodeToken(this.token);
  }

  get isExpired() {
    return this.helper.isTokenExpired(this.token);
  }

  get isAuth(): boolean {
    return this.token != null;
  }

  get token(): string {
    return localStorage.getItem("token");
  }

  set token(value) {
    localStorage.setItem("token", value);
  }


    get refreshToken(): string {
        return localStorage.getItem("refreshToken");
    }
    set refreshToken(value) {
        localStorage.setItem("refreshToken", value);
    }

    get clientName(): string {
        return localStorage.getItem("clientName");
    }

    set clientName(value) {
        localStorage.setItem("clientName", value);
    }

    get clientContactName(): string {
        return localStorage.getItem("clientContactName");
    }

    set clientContactName(value) {
        localStorage.setItem("clientContactName", value);
    }
    get clientNo(): string {
        return localStorage.getItem("clientNo");
    }

    set clientNo(value) {
        localStorage.setItem("clientNo", value);
    }

    get plasticPrice(): number {
        return Number(localStorage.getItem("plasticPrice"));
    }

    set plasticPrice(value) {
        localStorage.setItem("plasticPrice", value.toString());
    }

    get commissionRatio(): number {
        return Number(localStorage.getItem("commissionRatio"));
    }

    set commissionRatio(value) {
        localStorage.setItem("commissionRatio", value.toString());
    }



    get refreshIssued(): string {
        return localStorage.getItem("refreshIssued");
    }

    set refreshIssued(value) {
        localStorage.setItem("refreshIssued", value);
    }
    finishRefreshProcess() {
        localStorage.removeItem("refreshIssued");

    }

  unAuth() {
      localStorage.removeItem("token");
      localStorage.removeItem("refreshToken");
      sessionStorage.removeItem("role");

  }
}

export interface TokenUserModel {
  Id: number;
  UserName: string;
  FullName: string;
  //role: string;
}
