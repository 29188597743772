import {Injectable} from '@angular/core';
import {BehaviorSubject, Observable} from "rxjs";

export interface FabItem {
    text: any;
    routerLink?: any;
    tooltip?: string;
    click?: () => any;
}

@Injectable({
    providedIn: 'root'
})
export class FabShortcutService {
    show = new BehaviorSubject(true);
    showCustom = new BehaviorSubject(false);
    private defaultItems: FabItem[] = [
        {
            text: "سند قبض",
            routerLink: "/billing/receiptvouchers",
            tooltip: "سند قبض",
        },
        {
            text: "سند صرف",
            routerLink: "/billing/paymentvouchers",
            tooltip: "سند صرف",
        },
        {
            text: "بيع بضاعة",
            routerLink: "/sales/salesform",
            tooltip:  "بيع بضاعة",
        },
        {
            text: " فواتير المبيعات ",
            routerLink: "/sales",
            tooltip: "فواتير المبيعات",
        },
        {
            text: "استلام بضاعة",
            routerLink: "/goods/new",
            tooltip: "استلام بضاعة",
        },

    ];

    constructor() {
    }

    private _items = new BehaviorSubject<FabItem[]>(this.defaultItems);

    set items(value: FabItem[]) {
        this.showCustom.next(true);
        this._items.next(value);
    }

    get itemsList(): Observable<FabItem[]> {
        return this._items.asObservable();
    }

    showDefault() {
        this.showCustom.next(false);
        this.show.next(true);
        this._items.next(this.defaultItems);
    }
}
