import {Injectable} from "@angular/core";
import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree} from "@angular/router";
import {Observable} from "rxjs";
import {AuthService} from "./services/auth.service";

@Injectable({
    providedIn: "root"
})
export class AccessGuard implements CanActivate {
    constructor(private authService: AuthService, private route: Router) {
    }

    canActivate(
        next: ActivatedRouteSnapshot,
        state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
        if (!this.authService.isAuth) {
            this.route.navigate(["/auth/login"]);
            return false;
        } else {
            return true;
        }
    }

}
