import {Inject, Injectable, InjectionToken, Optional} from "@angular/core";
import {HttpClient, HttpEvent, HttpHeaders, HttpParams, HttpResponse} from "@angular/common/http";
import {Configuration} from "../../rest";
import {Observable} from "rxjs";
import {CustomHttpUrlEncodingCodec} from "../../rest/encoder";

export const NEWSOFT_BASE_PATH = new InjectionToken<string>('newSoftBasePath');

@Injectable()
export class NewSoftImageServiceService {
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();
    protected basePath = "https://localhost/SandiegoZoo";

    constructor(protected httpClient: HttpClient, @Optional() @Inject(NEWSOFT_BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
        if (basePath) {
            this.basePath = basePath;
        }
        if (configuration) {
            this.configuration = configuration;
            this.basePath = basePath || configuration.basePath || this.basePath;
        }
    }

    /**
     * Download File
     *
     * @param id
     * @param imageSizeType
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public downloadFile(id: number, imageSizeType: number, observe?: "body", reportProgress?: boolean): Observable<Blob>;
    public downloadFile(id: number, imageSizeType: number, observe?: "response", reportProgress?: boolean): Observable<HttpResponse<Blob>>;
    public downloadFile(id: number, imageSizeType: number, observe?: "events", reportProgress?: boolean): Observable<HttpEvent<Blob>>;
    public downloadFile(id: number, imageSizeType: number, observe: any = "body", reportProgress: boolean = false): Observable<any> {
        if (id === null || id === undefined) {
            throw new Error("Required parameter id was null or undefined when calling downloadFile.");
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (imageSizeType !== undefined) {
            queryParameters = queryParameters.set("imageSizeType", String(imageSizeType));
        }

        let headers = this.defaultHeaders;

        // authentication (medcor_oath) required
        // if (this.configuration.accessToken) {
        //     const accessToken = typeof this.configuration.accessToken === "function"
        //         ? this.configuration.accessToken()
        //         : this.configuration.accessToken;
        //     headers = headers.set("Authorization", "Bearer " + accessToken);
        // }

        headers = headers.set("Authorization", "Bearer " + "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOiIyIiwiZ2l2ZW5fbmFtZSI6IjIiLCJlbWFpbCI6IiIsImVtcGxveWVlSWQiOiIxOCIsIm5lZWRSZXNldFBhc3N3b3JkIjoiRmFsc2UiLCJpc1N1cGVyQWRtaW4iOiJUcnVlIiwianRpIjoiZjM0YTUzYzUtMWZlOS00YTBiLWEwNmEtMDkzMjg2ODcyODc0IiwiaWF0IjoxNTc1MTAyMTY2LCJyb2xlIjoiYXBpX2FjY2VzcyIsImlkIjoiMTAiLCJmdWxsbmFtZUFyIjoi2LnYsiDYp9mE2K_ZitmGINi52YTZiiDYudmI2K_YqSDYp9io2Ygg2K3Ys9in2YYiLCJmdWxsbmFtZUVuIjoi2LnYsiDYp9mE2K_ZitmGINi52YTZiiDYudmI2K_YqSDYp9io2Ygg2K3Ys9in2YYiLCJuYmYiOjE1NzUxMDIxNjYsImV4cCI6MTU3NTExMDE4NiwiaXNzIjoiaHR0cDovL2xvY2FsaG9zdDo1ODQ4OCIsImF1ZCI6Imh0dHA6Ly9sb2NhbGhvc3Q6NDIwMCJ9.oI9kIMdx7Q2HIVgKPvYEg6wCMI9jBY2woxay0rl0-mo");


        // to determine the Accept header
        const httpHeaderAccepts: string[] = [
            "application/octet-stream"
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set("Accept", httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [];

        return this.httpClient.get(`${this.basePath}/api/Employees/GetImage/${encodeURIComponent(String(id))}`,
            {
                responseType: "blob",
                withCredentials: this.configuration.withCredentials,
                headers,
                params: queryParameters,
                observe,
                reportProgress
            }
        );
    }

}
