import {NgModule} from "@angular/core";
import {CommonModule} from "@angular/common";
import {SecureImageDirectiveDirective} from "./secure-image-directive.directive";
import {NewSoftImageServiceService} from "./new-soft-image-service.service";
import {SecureFileDownloadDirective} from './secure-file-download.directive';
import {SecureFilePreviewDirective} from './secure-file-preview.directive';
import {RoleDirective} from './role.directive';


@NgModule({
    declarations: [SecureImageDirectiveDirective, SecureFileDownloadDirective, SecureFilePreviewDirective, RoleDirective],
    imports: [
        CommonModule
    ],
    exports: [SecureImageDirectiveDirective, SecureFileDownloadDirective, SecureFilePreviewDirective, RoleDirective],
    providers: [NewSoftImageServiceService]
})
export class DirectivesModule {
}
