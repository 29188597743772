import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { MainComponent } from "./shared/layout/main/main.component";
import { LayoutModule } from "./shared/layout/layout.module";
import { AccessGuard } from "./access.guard";
import { RoleGuard } from "./role-guard.service";
import { HttpClientModule, HttpClient } from '@angular/common/http';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';

const routes: Routes = [
  {
    path: "",
    component: MainComponent,
    children: [
        { path: "", redirectTo: "dashboard", pathMatch: "full" },
      {
        path: "settings",
        loadChildren: () =>
          import("./features/settings/settings.module").then(
            m => m.SettingsModule
          ),
        canActivate: [RoleGuard],
        data: { roles: ["sysManager"] }
      },
      {
        path: "goods",
        loadChildren: () =>
          import("./features/goods/receive-goods/receive-goods.module").then(
            m => m.ReceiveGoodsModule
          ),
        canActivate: [RoleGuard],
        data: { roles: ["sysManager"] }
      },

      {
        path: "lookup",
        loadChildren: () =>
          import("./features/lookup/lookup.module").then(m => m.LookupModule),
        canActivate: [RoleGuard],
        data: { roles: ["sysManager"] }
      },

      {
        path: "driver",
        loadChildren: () =>
          import("./features/driver/driver.module").then(m => m.DriverModule),
      //  canActivate: [RoleGuard],
        data: {roles: ["sysManager"]}
      },
      {
        path: "vendor",
        loadChildren: () =>
          import("./features/vendor/vendores.module").then(m => m.VendoresModule),
      //  canActivate: [RoleGuard],
        data: {roles: ["sysManager"]}
      },
      {
        path: "buyer",
        loadChildren: () =>
          import("./features/buyer/buyer.module").then(m => m.BuyerModule),
      //  canActivate: [RoleGuard],
        data: {roles: ["sysManager"]}
      },
      {
        path: "sales",
        loadChildren: () =>
          import("./features/sales/sales.module").then(m => m.SalesModule),
      //  canActivate: [RoleGuard],
        data: {breadcrumbs: ["sale"],roles: ["sysManager"]}
      },
      {
        path: "reports",
        loadChildren: () =>
          import("./features/reports/reports.module").then(m => m.ReportsModule),
      //  canActivate: [RoleGuard],
        data: {breadcrumbs: ["reports"],  roles: ["sysManager"]}
        },

        {
            path: "groups",
            loadChildren: () =>
                import("./features/group/group.module").then(m => m.GroupModule),
            //  canActivate: [RoleGuard],
            data: {  roles: ["sysManager"] }
        },
       
        {
            path: "user",
            loadChildren: () =>
                import("./features/user/user.module").then(
                    m => m.UserModule
                ),
            canActivate: [RoleGuard],
            data: { roles: ["sysManager"] }
        },
        {
            path: "dashboard",
            loadChildren: () =>
                import("./features/dashboard/dashboard.module").then(
                    m => m.DashboardModule
                ),
            canActivate: [RoleGuard],
            data: { breadcrumbs: ["home"], roles: ["sysManager"] }
        },
        {
            path: "damaged",
            loadChildren: () =>
                import("./features/damaged/damaged.module").then(
                    m => m.DamagedModule
                ),
            canActivate: [RoleGuard],
            data: { roles: ["sysManager"] }
        },
        {
            path: "billing",
            loadChildren: () =>
                import("./features/billing/billing.module").then(
                    m => m.BillingModule
                ),
            canActivate: [RoleGuard],
            data: { roles: ["sysManager"] }
        },
    ],
    canActivate: [AccessGuard]
  },
  {
    path: "auth",
    loadChildren: () =>
      import("./features/login/login.module").then(m => m.LoginModule)
  }
];

export function HttpLoaderFactory(http: HttpClient) {
    return new TranslateHttpLoader(http);
}

@NgModule({
    imports: [LayoutModule, RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' }),
        TranslateModule.forChild(
            {
                defaultLanguage: 'ar',
                loader: {
                    provide: TranslateLoader,
                    useFactory: HttpLoaderFactory,
                    deps: [HttpClient]
                }
            }) ],
  exports: [RouterModule]
})

export class AppRoutingModule {}
