import {Component} from "@angular/core";
import {FabShortcutService} from "../main/fab-shortcut.service";

@Component({
    selector: "smart-fab-shortcut",
    templateUrl: "./fab-shortcut.component.html"
})
export class FabShortcutComponent {


    constructor(public fabShortcutService: FabShortcutService) {
    }

}
