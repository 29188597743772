// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

import { HttpHeaders } from "@angular/common/http";

// export const environment = {
//   production: false,
//   hrLoginUrl: "http://185.225.116.27/#/auth/login?returnUrl=",
//   hrLogoutUrl: "http://185.225.116.27/#/auth/login?returnUrl=",
//   httpOptions: {
//     headers: new HttpHeaders({
//     })
//   }
// };
export const environment = {
  production: false,
  jsonAPI: "assets/data",
  // apiBaseUrl: 'http://waseemsabra-001-site1.etempurl.com/api/',
  // sharedBaseUrl: 'http://waseemsabra-001-site1.etempurl.com/api/',
  apiBaseUrl: "/backend/api/",
  sharedBaseUrl: "/backend/api/",
  urlPrefix: "",
  httpOptions: {
    headers: new HttpHeaders({}),
  },
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
