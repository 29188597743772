export * from './account.service';
import { AccountService } from './account.service';
export * from './driver.service';
import { DriverService } from './driver.service';
export * from './goodsReceipt.service';
import { GoodsReceiptService } from './goodsReceipt.service';
export * from './lookups.service';
import { LookupsService } from './lookups.service';
export * from './pdfGenerator.service';
import { PdfGeneratorService } from './pdfGenerator.service';
export * from './purchaser.service';
import { PurchaserService } from './purchaser.service';
export * from './role.service';
import { RoleService } from './role.service';
export * from './sales.service';
import { SalesService } from './sales.service';
export * from './seller.service';
import { SellerService } from './seller.service';
export * from './uploadFile.service';
import { UploadFileService } from './uploadFile.service';
export * from './userRole.service';
import { UserRoleService } from './userRole.service';
export * from './users.service';
import { UsersService } from './users.service';

export * from './damaged.service';
import { DamagedService } from './damaged.service';

export * from './billing.service';
import { BillingService } from './billing.service';

export * from './shared.service';
import { SharedService } from './shared.service';
export const APIS = [AccountService, DriverService, GoodsReceiptService, LookupsService, PdfGeneratorService, PurchaserService, RoleService, SalesService, SellerService, UploadFileService, UserRoleService, UsersService, SharedService, DamagedService,BillingService];
