/**
 * Correspondence  API 
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional } from '@angular/core';
import {
    HttpClient, HttpHeaders, HttpParams,
    HttpResponse, HttpEvent
} from '@angular/common/http';
import { CustomHttpUrlEncodingCodec } from '../encoder';

import { Observable } from 'rxjs';







import { BASE_PATH, COLLECTION_FORMATS } from '../variables';
import { Configuration } from '../configuration';
import { SharedResponse, PaginatedListViewModel } from '../index';
import { DamagedViewModel, DamagedDetailsViewModel } from "../model/damagedViewModel";


@Injectable()
export class DamagedService {

    protected basePath = 'https://localhost';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();

    constructor(protected httpClient: HttpClient, @Optional() @Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
        if (basePath) {
            this.basePath = basePath;
        }
        if (configuration) {
            this.configuration = configuration;
            this.basePath = basePath || configuration.basePath || this.basePath;
        }
    }

    /**
     * @param consumes string[] mime-types
     * @return true: consumes contains 'multipart/form-data', false: otherwise
     */
    private canConsumeForm(consumes: string[]): boolean {
        const form = 'multipart/form-data';
        for (const consume of consumes) {
            if (form === consume) {
                return true;
            }
        }
        return false;
    }


    /**
     * 
     * 
     * @param obj 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public createDamaged(obj?: DamagedViewModel, observe?: 'body', reportProgress?: boolean): Observable<SharedResponse<DamagedViewModel>>;
    public createDamaged(obj?: DamagedViewModel, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<SharedResponse<DamagedViewModel>>>;
    public createDamaged(obj?: DamagedViewModel, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<SharedResponse<DamagedViewModel>>>;
    public createDamaged(obj?: DamagedViewModel, observe: any = 'body', reportProgress: boolean = false): Observable<any> {


        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<SharedResponse<DamagedViewModel>>(`${this.basePath}/api/Damaged/CreateDamaged`,
            obj,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param id 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public deleteDamaged(id?: number, observe?: 'body', reportProgress?: boolean): Observable<SharedResponse<boolean>>;
    public deleteDamaged(id?: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<SharedResponse<boolean>>>;
    public deleteDamaged(id?: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<SharedResponse<boolean>>>;
    public deleteDamaged(id?: number, observe: any = 'body', reportProgress: boolean = false): Observable<any> {


        let queryParameters = new HttpParams({ encoder: new CustomHttpUrlEncodingCodec() });
        if (id !== undefined && id !== null) {
            queryParameters = queryParameters.set('id', <any>id);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.delete<SharedResponse<boolean>>(`${this.basePath}/api/Damaged/DeleteDamaged`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getAllDamaged(observe?: 'body', reportProgress?: boolean): Observable<SharedResponse<Array<DamagedDetailsViewModel>>>;
    public getAllDamaged(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<SharedResponse<Array<DamagedDetailsViewModel>>>>;
    public getAllDamaged(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<SharedResponse<Array<DamagedDetailsViewModel>>>>;
    public getAllDamaged(observe: any = 'body', reportProgress: boolean = false): Observable<any> {

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<SharedResponse<Array<DamagedDetailsViewModel>>>(`${this.basePath}/api/Damaged/GetAllDamaged`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param id 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getDamaged(id?: number, observe?: 'body', reportProgress?: boolean): Observable<SharedResponse<DamagedDetailsViewModel>>;
    public getDamaged(id?: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<SharedResponse<DamagedDetailsViewModel>>>;
    public getDamaged(id?: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<SharedResponse<DamagedDetailsViewModel>>>;
    public getDamaged(id?: number, observe: any = 'body', reportProgress: boolean = false): Observable<any> {


        let queryParameters = new HttpParams({ encoder: new CustomHttpUrlEncodingCodec() });
        if (id !== undefined && id !== null) {
            queryParameters = queryParameters.set('id', <any>id);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<SharedResponse<DamagedDetailsViewModel>>(`${this.basePath}/api/Damaged/GetDamagedItem`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }


    /**
     * 
     * 
     * @param obj 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public updateDamaged(obj?: DamagedViewModel, observe?: 'body', reportProgress?: boolean): Observable<SharedResponse<DamagedViewModel>>;
    public updateDamaged(obj?: DamagedViewModel, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<SharedResponse<DamagedViewModel>>>;
    public updateDamaged(obj?: DamagedViewModel, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<SharedResponse<DamagedViewModel>>>;
    public updateDamaged(obj?: DamagedViewModel, observe: any = 'body', reportProgress: boolean = false): Observable<any> {


        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.put<SharedResponse<DamagedViewModel>>(`${this.basePath}/api/Damaged/UpdateDamaged`,
            obj,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

}
