/**
 * Correspondence  API 
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */


export class StoreRemainingItemsVw { 
    sellerId?: number;
    sellerName?: string;
    itemId?: number;
    itemCode?: string;
    itemTitle?: string;
    masterReceiveId?: number;
    unitId?: number;
    unitTitle?: string;
    weightUnitId?: number;
    quantity?: number;
    salesQuantity?: number;
    remainingQuantity?: number;
    remainingWeight?: number;
    sellingPrice: number;
      receivedDate: Date;

}
