import {Injectable} from "@angular/core";
import {ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot, UrlTree} from "@angular/router";
import {Observable} from "rxjs";
import {UserSettingsService} from "./services/user-settings.service";

@Injectable({
    providedIn: "root"
})
export class RoleGuard implements CanActivate {

    constructor(private userSettingsService: UserSettingsService) {
    }

    canActivate(
        next: ActivatedRouteSnapshot,
        state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
        // if (next.data.roles) {
        //     return next.data.roles.every((r) => {
        //         return this.userSettingsService.roles.indexOf(r) !== -1;
        //     });
        // } else {
            return true;
        // }
    }

}
