export enum ServiceLinks {
    None = '',
    baseUrl = '/backend/api',
    //baseUrl = 'http://localhost:52710/api',
    searchDriver = 'driver/search',
    searchGoods = 'GoodsReceipt/search',
    searchDamaged = 'damaged/search',
    searchSaleList = 'Sales/search',
    searchVoucher = 'BillManagement/search',
    searchLookup = 'lookups/search',
    accountDetailsReport = 'report/SearchAccountingDetails',
    accountSummaryReport = 'report/SearchAccountingSummary',
    receivedItemReport = 'report/ReceivedItemsReport',
    saleItemReport = 'report/SaleItemsReport',
    itemReport = 'report/ItemsReport',




}

export enum Modules {
    Drivers = 'drivers',
    Roles = 'roles',
    Users = 'users',
    Purchaser = 'purchasers',
    Buyers = 'buyers',
    Reports = 'report',
    Damaged = 'damaged',
    Sales = 'sales',
    Billing = 'billing',
    Lookup = 'lookup',
    Goods = 'GoodsReceipt'




}