import {ChangeDetectionStrategy, Component} from "@angular/core";
import {Store} from "@ngrx/store";
import {mobileNavigation} from "src/app/store/navigation";
import {APP_CONFIG} from "src/app/app.config";
import * as settings from "../../../store/settings";
import {FabShortcutService} from "./fab-shortcut.service";

@Component({
    selector: "smart-main",
    templateUrl: "./main.component.html",
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class MainComponent {

    app = APP_CONFIG.app;
    vm$ = this.store.select(settings.selectSettingsState);

    constructor(private store: Store<any>, public fabShortcutService: FabShortcutService) {
    }

    closeMobileNav($event: MouseEvent) {
        $event.preventDefault();
        this.store.dispatch(mobileNavigation({open: false}));
    }

}
