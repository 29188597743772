import { Component, OnInit, Input } from '@angular/core';
//import { SharedService } from '../../../Services/shared.service';
import { Router } from '@angular/router';
import { FormGroup, FormControl, Validators, Form } from '@angular/forms';
import { Gridconfig } from "../../../models/remoteGrid/gridConfig";
import { SharedService } from "../../../rest/api/shared.service";
import { first, finalize } from "rxjs/operators";
import { BehaviorSubject } from "rxjs";
import { TranslateService } from "@ngx-translate/core";

@Component({
    selector: 'remote-grid',
    templateUrl: './remote-grid.component.html',
    styleUrls: ['./remote-grid.component.scss']
})
export class RemoteGridComponent implements OnInit {
    @Input()
    test: string;
    @Input() config: any;
    @Input() cssClasses =
        "table m-0 table-bordered table-striped table-hover table-sm";
    // @Input() cssClasses = "table m-0 table-bordered table-dark table-light table-hover table-striped table-sm";
    @Input() theadClasses = "bg-primary-900";
    @Input() tbodyClasses = "";
    isLoading$ = new BehaviorSubject<boolean>(false);
    selectedRowIndex = -1;
    reportGroupingList = [];
    pagerIndex = 0;
    constructor(private sharedService: SharedService, private router: Router, private translate: TranslateService) {

    }
 

    loadData(step: number, type = '') {
        if (type == 'next') {
            if (this.config.Filter.PaginationDetails.pageNumber != 1 && this.config.Filter.PaginationDetails.pageNumber % 10 == 0) {
                this.pagerIndex += 10;
            }
            if (this.config.Filter.PaginationDetails.pageNumber > this.config.Filter.PaginationDetails.totalPages -
                (this.config.Filter.PaginationDetails.totalPages % 10)) {
                this.pagerIndex = this.config.Filter.PaginationDetails.totalPages -
                    (this.config.Filter.PaginationDetails.totalPages % 10);
            }
        }
        if (type == 'previous') {
            if (this.config.Filter.PaginationDetails.pageNumber != 1 && this.config.Filter.PaginationDetails.pageNumber % 10 == 1) {
                this.pagerIndex -= 10;
            }
        }

        this.isLoading$.next(true);
        if (this.config.RemoteData) {

            this.config.Filter.PaginationDetails.pageNumber = step;

            this.config.Columns.forEach((e) => {
                if (e.SearchValue) {
                    this.config.Filter.FilterObject[e.BindingName] = e.SearchValue;
                } else {
                    if (this.config.ColumnSearch) {
                        this.config.Filter.FilterObject[e.BindingName] = "";
                    }
                }
            });
            this.sharedService.searchData(this.config.Filter, this.config.ServiceLink)
                .pipe(finalize(() => this.isLoading$.next(false)))
                .subscribe(res => {
                    this.config.DataList = res.result;
                    console.log(this.config.DataList);
                    if (this.config.HasGrouping) {
                        this.reportGroupingList = [];
                        for (const key of Object.keys(this.config.DataList)) {
                            this.reportGroupingList.push(key);
                        }
                    }
                    this.config.Filter.PaginationDetails = res.pagination;

                });
        } else {
            this.isLoading$.next(false);
        }
    }
    getCounter() {
        return new Array(10);
    }
    getTotalPages() {
        if (this.config.Filter.PaginationDetails) {

            if (this.config.Filter.PaginationDetails.pageNumber > this.config.Filter.PaginationDetails.totalPages -
                (this.config.Filter.PaginationDetails.totalPages % 10)) {
                return new Array(this.config.Filter.PaginationDetails.totalPages % 10);

            } else {
                if (this.config.Filter.PaginationDetails.totalPages >= 10)
                    return new Array(10);
                else {
                    return new Array(this.config.Filter.PaginationDetails.totalPages);
                }
            }

        } else {
            return new Array(0);
        }
    }
    view(itm: any) {
        this.config.SelectedItem = itm;
        this.config.view();
    }
    edit(itm: any, index: number) {
        this.config.InEditMode = true;
        this.config.SelectedItemIndex = index;
        if (this.config.editCallBack) {
            this.config.SelectedItem = itm;
            this.config.editCallBack();
        } else {
            this.router.navigate([this.config.Module, 'edit', itm.Id]);
        }
    }

    delete(index: number) {
        this.config.deleteCallBack(index);
    }
    ngOnInit() {

        this.translate.get('invoiceNo').subscribe((text: string) => {

        });
        if (!this.config.RemoteData) {
            this.isLoading$.next(false);

        } else {
            this.isLoading$.next(true);

        }
        this.loadData(this.config.Filter.PaginationDetails.pageNumber);
        this.config.refreshCallBack = (firstTimeLoad = false) => {
            if (firstTimeLoad) {
                this.config.Columns.forEach((e) => {
                    this.config.Filter.FilterObject[e.BindingName] = '';
                    e.SearchValue = '';
                });
            }
            this.loadData(this.config.Filter.PaginationDetails.pageNumber);
        };
    }

    onSelect(index: number, row: any) {
        if (this.config.HasSelection) {
            this.selectedRowIndex = index;
            this.config.SelectedItem = row;
        }
    }
    refresh() {
        console.log(this.config.Filter);
        this.loadData(this.config.Filter.PaginationDetails.pageNumber);
    }
    calculateTotal(columnName: string) {
        var total = 0;
        if (this.config.DataList && this.config.HasGrouping === false) {
            this.config.DataList.forEach(function (item, index) {
                if (item[columnName]) {
                    total = total + parseFloat(item[columnName]);
                }
            });
        } else if (this.config.HasGrouping) {

            for (const key of Object.keys(this.config.DataList)) {
                this.config.DataList[key].forEach(function (item, index) {
                    if (item['Amount']) {
                        total = total + parseFloat(item['Amount']);
                    }
                });
            }


        }
        return parseFloat(total.toString()).toFixed(2);
    }

    getTotal(field: string) {
        if (this.config.DataList && this.config.DataList.length > 0) {
            return this.config.DataList.reduce((acc, val) => acc + val[field], 0);
        } else {
            return 0;
        }
    }

    testDirColumnCount() {
        var rightCount = 0;
        var leftCount = 0;
        this.config.Columns.forEach(function (i, v) {
            if (i.TestDir === 'Right') {
                rightCount += 1;
            }

            if (i.TestDir === 'Left') {
                leftCount += 1;
            }
        });

        return { right: rightCount, left: leftCount };
    }

    calculateGroupTotal(key: string) {
        var total = 0;
        this.config.DataList[key].forEach(function (item, index) {
            if (item['Amount']) {
                total = total + parseFloat(item['Amount']);
            }
        });

        return parseFloat(total.toString()).toFixed(2);
    }
    check() {
    }
}
