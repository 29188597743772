import {Directive, ElementRef, Input, TemplateRef, ViewContainerRef} from "@angular/core";
import {UserSettingsService} from "../../services/user-settings.service";

@Directive({
    // tslint:disable-next-line:directive-selector
    selector: "[appRole]"
})
export class RoleDirective {

    constructor(private element: ElementRef,
                private templateRef: TemplateRef<any>,
                private viewContainer: ViewContainerRef, private userSettingsService: UserSettingsService) {
    }

    @Input()
    set appRole(val: string[]) {
        if (val) {
            if (
                val.every((r) => {
                    return this.userSettingsService.roles.indexOf(r) !== -1;
                })
            ) {
                this.viewContainer.createEmbeddedView(this.templateRef);
            } else {
                this.viewContainer.clear();
            }
        } else {
            this.viewContainer.createEmbeddedView(this.templateRef);
        }
    }

}
