import {Component, Input, OnInit} from "@angular/core";
import {PurchaserViewModel} from "src/app/rest";
import {BehaviorSubject, Subject} from "rxjs";
import {ActivatedRoute, Router} from "@angular/router";
import {first} from "rxjs/operators";
import {NgForm} from "@angular/forms";
import {PurchaserService} from "../../../rest";
import { BsModalRef } from "ngx-bootstrap/modal";
import { TranslateService } from "@ngx-translate/core";
 
@Component({
    selector: "smart-buyer-form",
    templateUrl: "./buyer-form.component.html",
    styleUrls: ["./buyer-form.component.scss"]
})
export class BuyerFormComponent implements OnInit {

    @Input() isDialog = false;
    onClose = new Subject();

    title = this.translate.instant("details");

    purchaserObs: BehaviorSubject<PurchaserViewModel> = new BehaviorSubject<PurchaserViewModel>({});

    constructor(
        private activatedRoute: ActivatedRoute,
        private router: Router,
        private purchaserService: PurchaserService,
        public bsModalRef: BsModalRef,
        private translate : TranslateService
    ) {
    }

    ngOnInit() {
        this.activatedRoute.params.pipe(first()).subscribe(params => {
            if (params.id) {
                this.purchaserService.getPurchaser(params.id).subscribe(res => {
                    this.purchaserObs.next(res.result);
                });
            }
        });
    }

    save(f: NgForm) {
        if (f.form.valid) {
            this.purchaserObs.pipe(first()).subscribe(purchaser => {
                if (this.purchaserObs.getValue().id > 0) {
                    this.purchaserService.updatePurchaser(purchaser).subscribe(res => {
                        if (!this.isDialog) {
                            this.router.navigate(["/buyer"], { relativeTo: this.activatedRoute });

                        } else {
                            this.confirm(purchaser);
                        }
                    });
                } else {
                    this.purchaserService.createPurchaser(purchaser).subscribe(res => {
                        if (!this.isDialog) {
                            this.router.navigate(["/buyer"], { relativeTo: this.activatedRoute });

                        } else {
                            this.confirm(purchaser);
                        }
                    });
                }
            });
        } else {
            f.form.markAllAsTouched();
        }
    }

    confirm(data: any) {
        this.onClose.next(true);
        this.onClose.complete();
        this.bsModalRef.hide();
    }

    cancel() {
        this.onClose.next(false);
        this.onClose.complete();
        this.bsModalRef.hide();
    }

}
