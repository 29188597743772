import { Inject, Injectable, Optional } from '@angular/core';
import {
    HttpClient, HttpHeaders, HttpParams,
    HttpResponse, HttpEvent
} from '@angular/common/http';
import { CustomHttpUrlEncodingCodec } from '../encoder';

import { Observable } from 'rxjs';

import { ServiceLinks, Modules } from "../../rest/service-links.enum";



import { BASE_PATH, COLLECTION_FORMATS } from '../variables';
import { Configuration } from '../configuration';
import { SharedResponse, PaginatedListViewModel  } from '../index';
import baseUrl = ServiceLinks.baseUrl;
const httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json' })
};
@Injectable()
export class SharedService {
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();
    constructor(protected httpClient: HttpClient) {
    }

    /**
      * 
      * 
      * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
      * @param reportProgress flag to report request and response progress.
      */
    public searchData(filter: any, serviceUrl: ServiceLinks, observe?: 'body', reportProgress?: boolean): Observable<SharedResponse<Array<any>>>;
    public searchData(filter: any, serviceUrl: ServiceLinks,observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<SharedResponse<Array<any>>>>;
    public searchData(filter: any, serviceUrl: ServiceLinks,observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<SharedResponse<Array<any>>>>;
    public searchData(filter: any, serviceUrl: ServiceLinks,observe: any = 'body', reportProgress: boolean = false): Observable<any> {

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.post<SharedResponse<Array<any>>>(`${ServiceLinks.baseUrl}/${serviceUrl}`,filter,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }
    //public searchData(filter: any, serviceUrl: ServiceLinks) {
    //    // this won't actually work because the StarWars API
    //    // is read-only. But it would look like this:
    //    return this
    //        .http
    //        .post<CustomResponse>(ServiceLinks.baseUrl + serviceUrl,
    //            JSON.stringify(filter), httpOptions);
    //}

    //public autoComplete(filter: any, serviceUrl: ServiceLinks) {
    //    // this won't actually work because the StarWars API
    //    // is read-only. But it would look like this:
    //    return this
    //        .http
    //        .get<CustomResponse>(ServiceLinks.baseUrl + serviceUrl,
    //            { params: new HttpParams().set('filter', filter) });
    //}


    //public getLookups(type: string) {
    //    // this won't actually work because the StarWars API
    //    // is read-only. But it would look like this:
    //    return this
    //        .http
    //        .get<CustomResponse>(ServiceLinks.baseUrl + ServiceLinks.getLookup,
    //            { params: new HttpParams().set('lookupType', type) });
    //}

    //public getErrorList(badRequestErrors: any) {
    //    var errorList = [];
    //    if (badRequestErrors && badRequestErrors.status === 400 && badRequestErrors.error.ModelState) {
    //        for (const error in badRequestErrors.error.ModelState) {
    //            errorList.push(badRequestErrors.error.ModelState[error]);
    //        }
    //    }
    //    return errorList;
    //}
}