/**
 * Correspondence  API 
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */


export interface SalesItemViewModel { 
    id?: number;
    quantity?: number;
    weight?: number;
    plasticCount?: number;
    plasticPrice?: number;
    price?: number;
    note?: string;
    sellerId: number;
    itemId: number;
    unitId?: number;
    weightUnitId?: number;
    masterSalesId?: number;
    receivingItemId?: number;
    isPlasticRetrieved?: number;
    receivedDate? :string;

}
