import {
  Component,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
  Output,
  EventEmitter
} from "@angular/core";
import { sortingTypeEnum } from "src/app/models/enum/sortingTypeEnum";
import { TableColumnModel } from "src/app/models/dataTable/tableColumnModel";
import * as XLSX from "xlsx";
import { PrintService } from "../../../shared/print/print.service";

export class DynamicTableDataModel<T> {
  data: T[];
  totalRows: number;
  currentPage: number;
  pageSize: number;
}

export class PageChangeInfo {
  constructor() {}
  totalRows: number;
  currentPage: number;
  pageSize: number;
}

export interface IRowBtn<T> {
  iconClass: string;
  class: string;
  action: (row: T) => void;
  title: string;
  condition?: (row: T) => boolean;
  loading?: boolean;
}
@Component({
  selector: "smart-dynamic-table",
  templateUrl: "./dynamic-table.component.html",
  styleUrls: ["./dynamic-table.component.scss"]
})
export class DynamicTableComponent<T> implements OnInit, OnChanges {
  @Input() sourceDataList: DynamicTableDataModel<T>;
  @Input() cols: TableColumnModel[] = [];
  @Input() cssClasses =
    "table m-0 table-bordered table-striped table-hover table-sm";
  // @Input() cssClasses = "table m-0 table-bordered table-dark table-light table-hover table-striped table-sm";
  @Input() theadClasses = "bg-primary-900";
  @Input() tbodyClasses = "";
  @Input() rowBtns: IRowBtn<T>[];
  @Input() pageSize = 0;
  @Output() pageChange: EventEmitter<PageChangeInfo> = new EventEmitter();
  tempList: T[];
  currentPage = 1;
  pageNumber: number[];
  totalRecourds = 0;
  start = 0;
  end = 0;
  pageNumberSize = 0;
  searchValues = "";
  colToSort: string;
  sortMode: sortingTypeEnum = sortingTypeEnum.asc;

  constructor(private printService: PrintService) {}

  ngOnChanges(changes: SimpleChanges): void {
    this.buildTempList();
    this.search();
  }

  ngOnInit() {
    this.buildTempList();
  }

  PageSizeChange() {
    if (this.pageSize === 0) {
      this.pageSize = this.sourceDataList.pageSize;
    }
    this.setCurrentPage(1);
  }

  setCurrentPage(page: number) {
    if (page > 0) {
      const pageInfo = new PageChangeInfo();
      pageInfo.currentPage = page;
      pageInfo.pageSize = this.pageSize;
      pageInfo.totalRows = this.totalRecourds;
      this.pageChange.emit(pageInfo);
      this.currentPage = page;
      this.buildTempList();
    }
  }

  exportToExcel(tableElement: HTMLTableElement) {
    const ws: XLSX.WorkSheet = XLSX.utils.table_to_sheet(tableElement);
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "Sheet1");

    /* save to file */
    XLSX.writeFile(wb, "export_" + new Date().toLocaleString() + ".xlsx");
  }

  sort(headerName: string) {
    this.colToSort = headerName;
    if (this.sortMode === sortingTypeEnum.asc) {
      this.sourceDataList.data.sort(this.sortByPropertyDesce(headerName));
      this.sortMode = sortingTypeEnum.desc;
    } else {
      this.sourceDataList.data.sort(this.sortByPropertyAsce(headerName));
      this.sortMode = sortingTypeEnum.asc;
    }
    this.buildTempList();
  }

  sortByPropertyAsce(property) {
    return (x, y) => {
      return x[property] === y[property]
        ? 0
        : x[property] > y[property]
        ? 1
        : -1;
    };
  }

  sortByPropertyDesce(property) {
    return (x, y) => {
      return x[property] === y[property]
        ? 0
        : x[property] < y[property]
        ? 1
        : -1;
    };
  }

  searchTextKeyUp(event: any) {
    if (event.code === "Enter") {
    }
    this.buildTempList();
  }

  search(): T[] {
    if (!this.sourceDataList) {
      return [];
    }
    if (!this.searchValues || this.searchValues.toString().trim() === "") {
      this.tempList = this.sourceDataList.data;
      this.totalRecourds = this.sourceDataList.totalRows;
    //   this.currentPage = this.sourceDataList.currentPage;
      if (this.pageSize === 0) {
        this.pageSize = this.sourceDataList.pageSize;
      }
      return this.tempList;
    } else {
      this.tempList = [];
      const colmm = this.cols;
      this.sourceDataList.data.filter(obj => {
        // tslint:disable-next-line:forin
        for (const col in colmm) {
          if (
            obj[colmm[col].field] &&
            obj[colmm[col].field]
              .toString()
              .toLowerCase()
              .includes(
                this.searchValues
                  .toString()
                  .trim()
                  .toLowerCase()
              )
          ) {
            this.tempList.push(obj);
            break;
          }
        }
      });
      this.totalRecourds = this.tempList.length;
      return this.tempList;
    }
  }

  getData(row: T, field: string) {
    let x = { ...row };
    for (const key of field.split(".")) {
      if (!x) {
        break;
      }
      x = x[key];
    }
    return x;
  }

  print(printElement: HTMLDivElement) {
    this.printService.print(printElement);
  }

  private buildTempList() {
    const arrayToDisplay = this.search();
    this.pageNumberSize = Math.ceil(
      this.totalRecourds / this.pageSize > 0
        ? this.totalRecourds / this.pageSize
        : 1
    );
    this.buildTablesPagingNumbers(arrayToDisplay);
  }

  private buildTablesPagingNumbers(arrayToDisplay: any[]) {
    this.buildPagesNavigator();
    if (arrayToDisplay.length > 0) {
      this.start = this.pageSize * this.currentPage - this.pageSize + 1;
      this.start = this.start === 0 ? 1 : this.start;
    } else {
      this.start = 0;
    }
    this.end = this.pageSize * this.currentPage;
    this.end = this.end > this.totalRecourds ? this.totalRecourds : this.end;
    this.tempList = arrayToDisplay;
    this.tempList = this.tempList.slice(
      (this.currentPage - 1) * this.pageSize,
      this.currentPage * this.pageSize
    );
  }

  private buildPagesNavigator() {
    if (this.pageNumberSize <= 7) {
      this.pageNumber = this.fillPagerArray(this.pageNumberSize);
    } else {
      const pageNumbring: any[] = [];
      if (this.currentPage <= 3) {
        pageNumbring.push(1);
        pageNumbring.push(2);
        pageNumbring.push(3);
        pageNumbring.push(4);
        pageNumbring.push(5);
        pageNumbring.push("...");
        pageNumbring.push(this.pageNumberSize);
      } else if (this.currentPage >= this.pageNumberSize - 2) {
        pageNumbring.push(1);
        pageNumbring.push("...");
        pageNumbring.push(this.pageNumberSize - 4);
        pageNumbring.push(this.pageNumberSize - 3);
        pageNumbring.push(this.pageNumberSize - 2);
        pageNumbring.push(this.pageNumberSize - 1);
        pageNumbring.push(this.pageNumberSize);
      } else {
        pageNumbring.push(1);
        pageNumbring.push("...");
        pageNumbring.push(this.currentPage - 1);
        pageNumbring.push(this.currentPage);
        pageNumbring.push(this.currentPage + 1);
        pageNumbring.push("...");
        pageNumbring.push(this.pageNumberSize);
      }
      this.pageNumber = pageNumbring;
    }
  }

  private fillPagerArray(numberToFill: number) {
    return Array(numberToFill)
      .fill(0)
      .map((x, i) => {
        i++;
        return i;
      });
  }
}
